.collaboratoritem{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;

}

.collaboratoritem_li {
  /* display: inline; */
  display: grid;
  /* grid-template-columns: repeat(3, 1fr);  */
  grid-template-columns: 2fr 1fr 1fr; 
  gap: 10px;
  text-align: left;
  width: 70%;
}