:root {
  --dark-red: #4d1207;
  --darker-blue: #12033c;  
  --neutral: rgb(172, 172, 172);
  --light-blue: #60a0ba;
  --dark-blue: #8ab3e8;
  --logo-blue: rgb(169, 188, 212);
  --yellow: rgb(247, 202, 24);
  --grey-color: #9da09d;
  --red-brand-color: #F95E5C; 
  --dark-black-color: #383838;

  --orange-brand: #FFB067;
  --yellow-brand: #FFED86;
  --blue-brand: #A2DCE7;
  --pink-brand: #F8CCDC;

  --blue-sands-brand: #29B3FF;
  --lightblue-sands-brand: #81EAFF;
  --darkyellow-sands-brand: #FBC00E;
  --yellow-sands-brand: #FFD774;

  --grey-color: #9da09d;
  --red-brand-color: #F95E5C;  
  --font-size: 18px;
  --dark-black-color: #383838; 
}

.close-button {
  font-size: 15px;
  width: 100%;
  padding-top: -10px;
}

.createtrip_modal {
  margin: 15px 15px 15px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
}

.createtrip_header {
  font-weight: 800;
  font-size: 20px;
  /* margin-top: 25px; */
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}

.createtrip_form {
  padding: 18px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.createtrip_input {
  border-bottom: none;
  /* width: 100%; */
  width: 600px;
  height: 48px;
  padding-left: 10px;
  border: 1px solid var(--grey-color);
  font-weight: 400;
  font-size: var(--font-size);
  margin: auto;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.createtrip_input.description {
  padding-top: 10px;
  height: 70px;
  margin-top: 10px;
}

.createtrip_container_start,
.createtrip_container_end {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  
}

.createtrip_container_end {
  position: relative;
  right: -4px;
}

.createtrip_input.date{
  width: 100%;
}

.tripformsubheader {
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  white-space: nowrap;
  margin-right: 10px;
}

.continue_button {
  cursor: pointer;
  height: 48px;
  font-size: var(--font-size);
  font-weight: 800;
  width: 500px;
  border-radius: 8px;
  /* margin-top: 18px; */
  color: black;
  -webkit-transition: all .5s ease-in-out;
  moz-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .4s ease-in-out;

  background-image: linear-gradient(to right, var(--blue-sands-brand), var(--lightblue-sands-brand), var(--yellow-sands-brand), var(--darkyellow-sands-brand));
  box-shadow: 0 2px 10px 0 rgba(129, 234, 255, 0.5);
  background-size: 300% 100%;
}

.continue_button:hover {
  transform: scale(1.005); 
  background-position: 100% 0;
  -webkit-transition: all .5s ease-in-out;
  moz-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  box-shadow: 0 2px 10px 0 rgba(251, 192, 14, 0.5);
}

.friends_list_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
 
}

.friends_list_container span{
  display: flex;
  flex-direction: row;
  background: linear-gradient(to right, var(--darkyellow-sands-brand), var(--blue-sands-brand));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 30px;
}

.friendsemail_container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  justify-items: center;
  align-items: center;
  text-align: center;
}

.removefriend_button {
  color: var(--blue-sands-brand);
  position: relative;
  top: 5%;
  transition: 0.5s;
}

.removefriend_button:hover {
  transform: scale(1.05);
}

.submiterror {
  color: red;
}

.trip_image_container {
  position: relative;
  right: -11px;
  /* display: inline-block; */
  display: flex;
  color: rgb(152, 151, 151);
  font-weight: 400;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
}

.trip_image_container span,
.trip_image_container p {
  margin-right: 10px;
  margin-left: 10px;
  color: black;
  font-weight: 700;
  font-size: 18px;
}

.images_input {
  position: absolute;
  left: -9999px;  
}

.trip_image_container label{
  display: inline-block;
  padding: 10px 15px;
  background-image: linear-gradient(to right, var( --yellow-sands-brand), var(--darkyellow-sands-brand));
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  transition: 0.5s;
  width: 160px;
  text-align: center;
}

.images_input_label:hover {
  transform: scale(1.005);
  box-shadow: #383838;
  box-shadow: 0 2px 10px 0 rgba(169, 171, 171, 0.5);

}

.trip_date_container {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.tripformsubheader {
  margin-left: 10px;
  margin-right: 5px;
  font-weight: 700;
  font-size: 18px;
  white-space: nowrap;
}

/* .addfriends_container {
  width: 100%;
  background-color: pink;
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;
} */

#createtrip_input_addfriends{
  width: 100%;
}

.addfriends_button {
  cursor: pointer;
  height: 48px;
  font-size: var(--font-size);
  font-weight: 800;
  /* width: 500px; */
  border-radius: 8px;
  margin-top: 18px;
  color: black;
  -webkit-transition: all .5s ease-in-out;
  moz-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .4s ease-in-out;

  background-image: linear-gradient(to right, var(--blue-sands-brand), var(--lightblue-sands-brand), var(--yellow-sands-brand), var(--darkyellow-sands-brand));
  box-shadow: 0 2px 10px 0 rgba(129, 234, 255, 0.5);
  background-size: 300% 100%;
  padding-left: 10px;
  padding-right: 10px;
  text-align: right;
  float: right;
}

.addfriends_button:hover {
  transform: scale(1.005); 
  background-position: 100% 0;
  -webkit-transition: all .5s ease-in-out;
  moz-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  box-shadow: 0 2px 10px 0 rgba(251, 192, 14, 0.5);
}