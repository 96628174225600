.text_container{
  text-align: center;
}

.video_h1{
  font-size: 80px;
  font-weight: 900;
  position: relative;
  top: -70px;
  -webkit-transition: all 2s ease-in-out;
  moz-transition: all 2s ease-in-out;
  -o-transition: all 2s ease-in-out;
  transition: all 2s ease-out;
  background-image: linear-gradient(to right, var(--yellow-sands-brand),var(--darkyellow-sands-brand), rgb(253, 134, 15), var(--lightblue-sands-brand), var(--blue-sands-brand));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke:0.5px white;
  background-size: 500% 100%;
  
}

.video_h1:hover{
  transform: scale(1.1);
  background-position: 100% 0;
  -webkit-transition: all 2s ease-in-out;
  moz-transition: all 2s ease-in-out;
  -o-transition: all 2s ease-in-out;
  transition: all 2s ease-in-out;
}

.video_h1::before {
  content: attr(data-bf);
  display: inline-block;
  /* color:white; */
  font-size: 20px;
  transition: 2s ease-out;
  position: relative;
  top:-90px;
  left: 40px;
  /* -webkit-text-stroke:unset; */
  -webkit-text-fill-color:black;
  -webkit-text-stroke-width: 0;
  background-size: 500% 100%;
}

.video_h1::after {
  content: attr(data-af);
  display: inline-block;
  color:white;
  font-size: 20px;
  transition: 2s ease-out;
  position: relative;
  top: 30px;
  right: 40px;
  -webkit-text-fill-color:black;
  background-image: linear-gradient(to right, black,black,white, black, black);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 0;
  background-size: 500% 100%;
}

.video_h1:hover::before {
  transform: scale(1.2) translate(-25px, -15px);
  background-position: 100% 0;
  -webkit-transition: all 2s ease-in-out;
  moz-transition: all 2s ease-in-out;
  -o-transition: all 2s ease-in-out;
  transition: all 2s ease-in-out;
}

.video_h1:hover::after {
  transform: scale(1.2) translate(25px, 15px);
  background-position: 100% 0;
  -webkit-transition: all 2s ease-in-out;
  moz-transition: all 2s ease-in-out;
  -o-transition: all 2s ease-in-out;
  transition: all 2s ease-in-out;
}
.text_p{
  color: white;
  font-size: 30px;
  font-weight: 300;
  margin-top: 25px;
}

.video_container{
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

#bg_video {
  position: absolute;
  z-index: -100;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

}

.about_container{
  background-color: var(--lightblue-sands-brand);
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  margin-top: -71px;
  padding-top: 30px;
  text-align: center;
}

.about_h1{
  font-size: 30px;
  font-weight: 600;
  font-style: bold;
}

.about_p{
  font-size: 25px;
  font-weight: 600;
  color: white;
  margin-top: 20px;
  margin-bottom: 20px;
}

.about_grid_container{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  width: 70%;
  margin: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}

.about_column{
  justify-items: center;
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: 10px;
  padding: 10px;
  transition: 1s;
}

.about_column:hover {
  transform: scale(1.005); 
  z-index: 2;
  box-shadow: 1px 1px 2px 1px #8a8888;
  border-radius: 8px;
}

.flip_card {
  background-color: transparent;
  width: 150px;
  height: 150px;
  perspective: 1000px;
}

.flip_card_inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}

.flip_card:focus {
    outline: 0;
}

.flip_card:hover .flip_card_inner,
.flip_card:focus .flip_card_inner{
  transform: rotateY(180deg);
}

.flip_card_front,
.flip_card_back {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.flip_card_front {
  background:var(--yellow-sands-brand);
  color: black;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flip_card_back {
  background: white;
  color: black;
  transform: rotateY(180deg);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
}

.about_bullet{
  font-size: 20px;
  font-weight: 600;
}












