:root {
  --dark-red: #4d1207;
  --darker-blue: #12033c;  
  --neutral: rgb(172, 172, 172);
  --light-blue: #60a0ba;
  --dark-blue: #8ab3e8;
  --logo-blue: rgb(169, 188, 212);
  --yellow: rgb(247, 202, 24);
  --grey-color: #9da09d;
  --red-brand-color: #F95E5C; 
  --dark-black-color: #383838;

  --orange-brand: #FFB067;
  --yellow-brand: #FFED86;
  --blue-brand: #A2DCE7;
  --pink-brand: #F8CCDC;

  --blue-sands-brand: #29B3FF;
  --lightblue-sands-brand: #81EAFF;
  --darkyellow-sands-brand: #FBC00E;
  --yellow-sands-brand: #FFD774;

  --grey-color: #9da09d;
  --red-brand-color: #F95E5C;  
  --font-size: 18px;
  --dark-black-color: #383838; 
}

.trips_container {
  display: flex;
  justify-content: center;
  align-items: center; 
  flex-direction: column; 
}

.trips_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 13px;
  padding-bottom: 20px;
  display: grid;
  gap: 20px;
  width: 80%;
  min-height: 358px;
  color: var(--dark-black-color);
  }

.header_message {
  display: flex;
  align-items: center;
  background-color: white;
  font-size: 25px;
  font-weight: 800;
  position: sticky;
  justify-content: center;
  /* z-index: 2; */
  height: 70px;
}

.profile_dropdown_navlink {
  text-align: right;
  margin: 20px;
  padding-right: 2px;
  -webkit-transition: all .5s ease-in-out;
  moz-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .4s ease-in-out;

  background-image: linear-gradient(to right, white, white, var(--blue-sands-brand), var(--lightblue-sands-brand), var(--yellow-sands-brand), var(--darkyellow-sands-brand));
  background-size: 700% 100%;
  border-radius: 8px;
}

.profile_dropdown_navlink:hover {
  cursor: pointer;
  background-image: linear-gradient(to right, white, var(--blue-sands-brand), var(--lightblue-sands-brand), var(--yellow-sands-brand), var(--darkyellow-sands-brand));
  background-size: auto;
  border-radius: 8px;
  background-position: 100% 0;
  -webkit-transition: all .5s ease-in-out;
  moz-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  box-shadow: 1px 1px 2px 1px #8a8888;  
}

.create_button {
  cursor: pointer;
  height: 48px;
  font-size: var(--font-size);
  font-weight: 800;
  width: 500px;
  border-radius: 8px;
  /* margin-top: 18px; */
  color: black;
  -webkit-transition: all .5s ease-in-out;
  moz-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .4s ease-in-out;

  background-image: linear-gradient(to right, var(--blue-sands-brand), var(--lightblue-sands-brand), var(--yellow-sands-brand), var(--darkyellow-sands-brand));
  box-shadow: 0 2px 10px 0 rgba(129, 234, 255, 0.5);
  background-size: 300% 100%;
}

.create_button:hover {
  transform: scale(1.005); 
  background-position: 100% 0;
  -webkit-transition: all .5s ease-in-out;
  moz-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  box-shadow: 0 2px 10px 0 rgba(251, 192, 14, 0.5);
}

.new_user_container {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -100px 0 0 -250px;
  
}

.new_user_container p {
  font-weight: bold;
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: 900;
}

.new_user_container span{
  background-image: linear-gradient(to right, white, var(--blue-sands-brand), var(--lightblue-sands-brand), var(--yellow-sands-brand), var(--darkyellow-sands-brand));
  background-size: auto;
  border-radius: 8px;
  background-position: 100% 0;
  -webkit-transition: all .5s ease-in-out;
  moz-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  box-shadow: 1px 1px 2px 1px #8a8888;  
  padding: 10px;
}

@media (min-width: 0px) {
  .trips_div {
      grid-template-columns: 1fr;
      justify-content: center; 
      align-items: center;

  }
}







