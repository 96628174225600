/* RESET */
h1, h2, h3, h4, h5, h6, p, div, span, nav, link, body {
  margin: 0px;
  padding: 0px;
  /* font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif; */
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* TODO Add site wide styles */
form > .errors {
  color: rgb(241, 19, 19);
  font-size: 16px;
  margin-top: 4px;
  margin-bottom: 4px;
  display: flex;
  align-items: left;
}

*, body {
  /* font-family: 'Montserrat', sans-serif; */
  font-family: sans-serif;
}

