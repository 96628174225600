:root {
  --grey-color: #9da09d;
  --red-brand-color: #F95E5C;  
  --font-size: 18px;
  --brand-color: #383838; 
}

.trip_item_container{
  transition: 0.5s;
  text-align: center;
  padding: 10px 5px 10px 5px;
}

.trip_grid {
  overflow: hidden;
  grid-gap: 15px; 
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: dense;
  padding: 0.5rem;
}

.trip_item_container:hover {
  transform: scale(1.005); 
  box-shadow: 1px 1px 2px 1px #8a8888;
  border-radius: 8px;
}

.left_column .right_column {
  flex: 50%;
}

.right_column {
  display: flex;
  flex-direction: column;
  align-items: center;
  
}

.trip_link {
  align-self: flex-start;
}

.trip_link ul {
  margin: 10px 0px;
  list-style: none;
  text-align: left;
}

.google_map_container {
  width: 100%;
  height: 26%;
  align-self: center;
}

.trip_title{
  font-size: 20px;
  font-weight: 700;
  /* width: max-content; */
}

.trip_title:hover,
.event_titles:hover {
  cursor: pointer;
}

.horizontal_line {
  border-top: 2px solid rgb(201, 201, 201);
}

