.collaboratoritem {
    display: inline;
}


[data-title]:hover:after {
    opacity: 1;
    transition: all 0.3s ease-in-out;
    visibility: visible;
}
[data-title]:after {
    content: attr(data-title);
    position: absolute;
    bottom: -50px;
    left: -70px;
    padding: 6px;
    color: black;
    white-space: nowrap; 
    -moz-border-radius: 8px; 
    -webkit-border-radius: 8px;  
    border-radius: 8px;  
    -moz-box-shadow: 0px 0px 4px #222;  
    -webkit-box-shadow: 0px 0px 4px #222;  
    box-shadow: 0px 0px 4px #222;  
    opacity: 0;
    z-index: 99999;
    visibility: hidden;
    font-family: sans-serif;
    background-color: white;
}
[data-title] {
    position: relative;
}
