:root {
  --dark-red: #4d1207;
  --darker-blue: #12033c;  
  --neutral: rgb(172, 172, 172);
  --light-blue: #60a0ba;
  --dark-blue: #8ab3e8;
  --logo-blue: rgb(169, 188, 212);
  --yellow: rgb(247, 202, 24);
  --grey-color: #9da09d;
  --red-brand-color: #F95E5C; 
  --dark-black-color: #383838;

  --orange-brand: #FFB067;
  --yellow-brand: #FFED86;
  --blue-brand: #A2DCE7;
  --pink-brand: #F8CCDC;

  --blue-sands-brand: #29B3FF;
  --lightblue-sands-brand: #81EAFF;
  --darkyellow-sands-brand: #FBC00E;
  --yellow-sands-brand: #FFD774;

  --grey-color: #9da09d;
  --red-brand-color: #F95E5C;  
  --font-size: 18px;
  --dark-black-color: #383838; 
}

.trip_address_input {
  border-bottom: none;
  width: 100%;
  /* width: 600px; */
  height: 48px;
  padding-left: 10px;
  border: 1px solid var(--grey-color);
  font-weight: 400;
  font-size: var(--font-size);
  margin: auto;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.addaddress_button {
  cursor: pointer;
  height: 48px;
  font-size: var(--font-size);
  font-weight: 800;
  /* width: 500px; */
  border-radius: 8px;
  margin-top: 18px;
  color: black;
  -webkit-transition: all .5s ease-in-out;
  moz-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .4s ease-in-out;

  background-image: linear-gradient(to right, var(--blue-sands-brand), var(--lightblue-sands-brand), var(--yellow-sands-brand), var(--darkyellow-sands-brand));
  box-shadow: 0 2px 10px 0 rgba(129, 234, 255, 0.5);
  background-size: 300% 100%;
  padding-left: 10px;
  padding-right: 10px;
  text-align: right;
  float: right;
}

.addaddress_button:hover {
  transform: scale(1.005); 
  background-position: 100% 0;
  -webkit-transition: all .5s ease-in-out;
  moz-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  box-shadow: 0 2px 10px 0 rgba(251, 192, 14, 0.5);
}

.geolocation_info {
  margin-top: 5px;
  /* margin-bottom: 5px; */
  font-weight: 500;
  font-size: 18px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.geolocation_header {
  margin-left: 10px;
  margin-right: 5px;
  font-weight: 700;
  font-size: 18px;
  white-space: nowrap;
}

.addaddress_button_container {
  /* background-color: pink; */
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;
}