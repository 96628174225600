:root {
    --dark-red: #4d1207;
    --darker-blue: #12033c;  
    --neutral: rgb(172, 172, 172);
    --light-blue: #60a0ba;
    --dark-blue: #8ab3e8;
    --logo-blue: rgb(169, 188, 212);
    --yellow: rgb(247, 202, 24);
    --grey-color: #9da09d;
    --red-brand-color: #F95E5C; 
    --dark-black-color: #383838;
  
    --orange-brand: #FFB067;
    --yellow-brand: #FFED86;
    --blue-brand: #A2DCE7;
    --pink-brand: #F8CCDC;
  
    --blue-sands-brand: #29B3FF;
    --lightblue-sands-brand: #81EAFF;
    --darkyellow-sands-brand: #FBC00E;
    --yellow-sands-brand: #FFD774;
  
    --grey-color: #9da09d;
    --red-brand-color: #F95E5C;  
    --font-size: 18px;
    --dark-black-color: #383838; 
  }
  
  .tripshowpage {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    width: 98%;
    border: 2px solid white;
    padding: 20px;
}

.tripshowtrippanel {
    /* display: flex; */
    justify-content: center;
    width: 45%;
    border: 2px solid var(--neutral );
    border-radius: 15px;
    padding-bottom: 70px;
    padding-left: 120px;
     
}

.Edit_Trip_Link,
.Edit_Event_Link {
    margin-right: 15px;
}

[data-title]:hover:after {
    opacity: 1;
    transition: all 0.3s ease-in-out;
    visibility: visible;
}
[data-title]:after {
    content: attr(data-title);
    position: absolute;
    bottom: -50px;
    left: -70px;
    padding: 6px;
    color: black;
    white-space: nowrap; 
    -moz-border-radius: 8px; 
    -webkit-border-radius: 8px;  
    border-radius: 8px;  
    -moz-box-shadow: 0px 0px 4px #222;  
    -webkit-box-shadow: 0px 0px 4px #222;  
    box-shadow: 0px 0px 4px #222;  
    opacity: 0;
    z-index: 99999;
    visibility: hidden;
    font-family: sans-serif;
    background-color: white;
}
[data-title] {
    position: relative;
}

.tripshow_title_container,
.eventshow_title_container {
    display: flex;
    justify-content: flex-start;
    position: relative;
    margin-bottom: 15px;
}

#tripshowtriptitle,
#eventitemtitle {
    font-size: 25px;
    font-weight: bold;
    flex: 0 1 auto;
    position: absolute;
    top:-15%;
    left: 50%;
    transform: translateX(-50%);
}

.edit_delete_buttons{
    text-align: right;
    flex: 0 1 auto;
    margin-left: auto;
}

.edit_delete_buttons_event{
    text-align: right;
    flex: 0 1 auto;
    margin-left: auto;
    position: relative;
    right: 30px;
}

.tripshoweventslist {
    display: block;
    overflow-y: scroll;
    width: 45%;
    /* height: 700px; */
    border: 2px solid var(--neutral);
    border-radius: 15px;

}

#tripshowstartdate span {
    font-weight: 700;
}

.tripshowinfo {
    width: 80%;
    margin: 20px 0px 20px 0px;
    
}
.tripshowinfoitem {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 18px;

}

.eventitembox:hover {
    transform: scale(1.005);
    background-image: linear-gradient(to right, var(--yellow-sands-brand),var(--lightblue-sands-brand));
}

.eventitembox {
    border: 2px grey solid;
    border-radius: 10px;
    transition: 0.5s;
    padding: 10px;
    margin: 10px;
    /* background-color: rgb(171, 187, 234); */
}

.eventitemitem_date span {
    font-weight: 700;
    margin-right: 5px;
}

.dateseperator {
    border-top: 1px solid darkgrey;
    min-height: 30px;   
}

.dateseperatortext {
    padding: 5px 0px 5px 0px;
    text-align: center;
}   

#eventitemtitle {
    font-size: larger;
    font-weight: bold;
    text-align: center;
}

#eventform {
    border-top: 1px solid lemonchiffon;
}

.trip_show_images_ul {
    text-align: center;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  

.trip_show_images_li {
    display: inline;
}

.trip_show_images {
    height: 55px;
    font-weight: 30px;
    border-radius: 8px;
    aspect-ratio: 1;
    object-fit: cover;
    display: inline;
    margin-bottom: 10px;
    margin-right: 10px;
}

@media only screen and (max-width: 1300px) {
  .tripshowpage  {
    transform: scale(0.6);
    position: relative;
    top: -120px;
  }
}

.tripshow_description_container{
    margin-top: 10px;
}

.createtrip_input {
    border-bottom: none;
    width: 100%;
    /* width: 440px; */
    height: 48px;
    padding-left: 10px;
    border: 1px solid var(--grey-color);
    font-weight: 400;
    font-size: var(--font-size);
    margin: auto;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}

.addfriends_button {
    cursor: pointer;
    height: 48px;
    font-size: var(--font-size);
    font-weight: 800;
    /* width: 500px; */
    border-radius: 8px;
    margin-top: 18px;
    color: black;
    -webkit-transition: all .5s ease-in-out;
    moz-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .4s ease-in-out;

    background-image: linear-gradient(to right, var(--blue-sands-brand), var(--lightblue-sands-brand), var(--yellow-sands-brand), var(--darkyellow-sands-brand));
    box-shadow: 0 2px 10px 0 rgba(129, 234, 255, 0.5);
    background-size: 300% 100%;
    padding-left: 10px;
    padding-right: 10px;
    text-align: right;
    float: right;
}

.addfriends_button:hover {
    transform: scale(1.005); 
    background-position: 100% 0;
    -webkit-transition: all .5s ease-in-out;
    moz-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    box-shadow: 0 2px 10px 0 rgba(251, 192, 14, 0.5);
}

.addfriend_span {
    font-weight: 700;
    font-size: 18px;
}

.addfriend_container:hover {
    cursor: pointer;
    transition: all .5s ease-in-out;
    transform: scale(1.02);
}

#tripshowtripcollaborators{
    display: block;
}

.addfreinds_form {
    margin-bottom: 80px;
}
