:root {
  --dark-red: #4d1207;
  --darker-blue: #12033c;  
  --neutral: rgb(172, 172, 172);
  --light-blue: #60a0ba;
  --dark-blue: #8ab3e8;
  --logo-blue: rgb(169, 188, 212);
  --yellow: rgb(247, 202, 24);
  --grey-color: #9da09d;
  --red-brand-color: #F95E5C; 
  --dark-black-color: #383838;

  --orange-brand: #FFB067;
  --yellow-brand: #FFED86;
  --blue-brand: #A2DCE7;
  --pink-brand: #F8CCDC;

  --blue-sands-brand: #29B3FF;
  --lightblue-sands-brand: #81EAFF;
  --darkyellow-sands-brand: #FBC00E;
  --yellow-sands-brand: #FFD774;

  --grey-color: #9da09d;
  --red-brand-color: #F95E5C;  
  --font-size: 18px;
  --dark-black-color: #383838; 
}

.session-form > label {
  width: 100%;
  padding: 4px 0;
  text-align: right;
}

.session-form > label > span {
  display: inline-block;
  padding: 0 10px;
}

.session-form > input {
  width: 200px;
}

.session-form > input[type="submit"] {
  width: 100%;
}

.session-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  margin-top: 100px;
  margin-left: 100px;
}


.agree-message {
  display: flex;
  color: rgb(152, 151, 151);
  font-weight: 00;
  font-size: 14px;
  margin-top: 20px;
}

.agree-message a {
  text-decoration: none;
  color:var( --blue-sands-brand);
  margin-left: 1px;
  margin-right: 1px;
}

.continue-span {
  margin-left: 3px;
  margin-right: 3px;
  font-weight: bold;
} 

.agree_span{
  padding-left: 5px;
  padding-right: 5px;
}

.profile_image_container {
  position: relative;
  display: inline-block;
  left: -115px;
  color: rgb(152, 151, 151);
  font-weight: 400;
  font-size: 18px;
}

.profile_image_container span {
  margin-right: 10px;
}

.image_input {
  position: absolute;
  left: -9999px;
}

.profile_image_container label {
  display: inline-block;
  padding: 10px 15px;
  background-image: linear-gradient(to right, var( --yellow-sands-brand), var(--darkyellow-sands-brand));
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  transition: 0.5s;
}

.image_input_label:hover {
  transform: scale(1.005);
  box-shadow: #383838;
  box-shadow: 0 2px 10px 0 rgba(169, 171, 171, 0.5);

}